<template>
  <div>
    <div class="produto-adicionar">
      <div class="conteudo-interno">
        <div class="nova-comunicacao-container">
          <h1>Adicionar Campanha</h1>
          <formulario-cadastro-campanhas :editar="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const FormularioCadastroCampanhas = () => import("@/components/elementos/paginas/FormularioCadastroCampanhas");
export default {
  name: "CadastroCampanhasAdicionar",
  components: {
    FormularioCadastroCampanhas,
  },
};
</script>
